// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$historian-app-primary: mat.m2-define-palette(mat.$m2-indigo-palette);
$historian-app-accent: mat.m2-define-palette(mat.$m2-pink-palette,
        A200,
        A100,
        A400);

// The warn palette is optional (defaults to red).
$historian-app-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$historian-app-theme: mat.m2-define-light-theme((color: (primary: $historian-app-primary,
                accent: $historian-app-accent,
                warn: $historian-app-warn,
            ),
            typography: mat.m2-define-typography-config(),
            density: 0,
        ));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($historian-app-theme);

/* You can add global styles to this file, and also import other style files */

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

.spacer {
    flex: 1 1 auto;
}

.nav-icon {
    margin-right: 8px;
    color: #555;
}

.grey {
    color: #555;
}

.full-width {
    width: 100%;
}

.footer-container {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    padding: 4px;
    font-size: 12px;
}

.footer-color {
    background-color: whitesmoke;
}

.active {
    border-right: 4px solid rgba(blue, 0.7);
    background-color: rgba(skyblue, 0.1);
}

.nav-item {
    margin: 18px 0px;
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: center;
}

.nav-list {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: small;
}

.org-name {
    display: flex;
    font-size: small;
    color: #308bd1;
}

.cursor-pointer {
    cursor: pointer;
}

.heading-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // padding: 12px;
    gap: 12px;
    margin-left: 8px;
}

.toast-message {
    position: fixed;
    top: 64px;
    right: 90px;
    z-index: 99;
}

.flex-row {
    display: flex;
    flex-direction: row;
    // TODO: remove align-items center and define a separate class for this
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.align-center {
    align-items: center;
}

.gap-12 {
    gap: 12px;
}

.align-end {
    align-items: end;
}

.padding-8 {
    padding: 8px;
}

.cursor-pointer {
    cursor: pointer;
}

.width-40 {
    width: 40%;
}

.width-20 {
    width: 20%;
}

.width-50 {
    width: 50%;
}

.width-30 {
    width: 30%;
}

.width-100 {
    width: 100%;
}

.padding-12 {
    padding: 12px;
}

.padding-6 {
    padding: 6px;
}


.gap-24 {
    gap: 24px;
    align-items: center;
}

.gap-4{
    gap: 4px;
}

.padding-4 {
    padding: 4px;
}

.justify-end {
    justify-content: end;
}


.card-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 2fr));
    gap: 16px;
    padding: 16px;
  }
  
  .card {
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .card-header {
    display: flex;
    align-items: center;
    gap: 12px;
    background-color: #f5f5f5;
    padding: 12px;
    border-bottom: 1px solid #e0e0e0;
  }
  
  .icon {
    // font-size: 36px;
    color: #1976d2;
  }
  
  .card-title {
    font-size: 18px;
    font-weight: bold;
    color: #424242;
  }
  
  .card-content {
    padding: 12px;
    font-size: 14px;
    color: #616161;
  }
  
  .card p {
    margin: 0;
  }